@import "global";

.dashly {

  .citrus {
    top: 0;
    right: -310px;
  }

  .section__cta {
    background-color: #439cb6;

    &:hover {
      background-color: darken(#439cb6, 5);
    }

    .arrow path {
      fill: #74d7e8;
    }

    &:after {
      background-color: #439cb6;
    }

  }

}